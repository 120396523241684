<template>
	<w-matrix :headers="headers" :loading="loadings > 0" :no-data-text="noDataText" :pagination.sync="pagination" :total-items="totalUsers" :value="users">
		<template v-for="(theme, index) in filteredThemesList" v-slot:[getHeaderSlotName(index)]>
			<th :id="index + 1" :key="index + 1">
				<v-layout align-center column justify-center>
					<v-checkbox
						color="secondary"
						:disabled="theme.status === 'disabled' || !canCheck"
						false-value="none"
						hide-details
						:indeterminate="theme.status === 'some'"
						:input-value="theme.status"
						:ripple="false"
						style="width: 24px"
						true-value="all"
						@change="themeToggled(theme.title, $event)"
					/>
					<v-flex text-xs-center v-text="theme.title" />
				</v-layout>
			</th>
		</template>
		<template v-slot:items="{ row }">
			<td class="text-xs-left">
				<v-layout align-center justify-start row>
					<v-flex mr-2 shrink>
						<v-checkbox
							color="secondary"
							:disabled="!canCheck"
							false-value="none"
							hide-details
							:indeterminate="usersFullAccessList[row.id] === 'some'"
							:input-value="usersFullAccessList[row.id]"
							:ripple="false"
							style="width: 24px"
							true-value="all"
							@change="userToggled(row, $event)"
						/>
					</v-flex>
					<v-flex shrink v-text="row.username ? row.username : row.email" />
				</v-layout>
			</td>
			<td v-for="(theme, index) in filteredThemesList" :key="index" class="text-xs-center">
				<v-layout align-center column justify-center>
					<v-checkbox
						:key="`${row.id}-${theme.id}`"
						color="primary"
						:disabled="row.themes[theme.title] === 'disabled' || !canCheck"
						false-value="none"
						hide-details
						:indeterminate="row.themes[theme.title] === 'some'"
						:input-value="row.themes[theme.title]"
						true-value="all"
						style="width: 24px"
						@change="userThemeToggled(row, theme.title, $event)"
					/>
				</v-layout>
			</td>
		</template>
	</w-matrix>
</template>

<script>
import ThemesModuleGuard from '@/mixins/ModulesGuards/Themes/ThemesModuleGuard'

export default {
	name: 'ThemeAssignmentMatrix',
	mixins: [ThemesModuleGuard],
	data: function () {
		return {
			users: [],
			loadings: 0,
			pagination: {
				page: 1,
				rowsPerPage: 15
			},
			totalUsers: 0
		}
	},
	computed: {
		canCheck: function () {
			return this.service.teamManager.rights.canCheck()
		},
		filteredThemesList: function () {
			let result = []
			if (this.selectedThemes.length > 0) {
				result = this.themesList.filter(theme => this.selectedThemes.includes(theme.title))
			} else {
				result = [...this.themesList]
			}
			return result
		},
		filters: function () {
			return this.service.teamManager.store.getFilters()
		},
		headers: function () {
			let result = [
				{
					text: this.$t('themes.team-manager.collaborators')
				}
			]
			this.filteredThemesList.forEach(theme => {
				result.push({
					text: theme.title
				})
			})
			return result
		},
		noDataText: function () {
			let result = null
			if (this.loadings > 0) {
				result = this.$t('themes.team-manager.loading-progress')
			} else if (this.filters.search) {
				result = this.$t('themes.team-manager.no-data-found-for-search', { search: this.filters.search })
			} else {
				result = this.$t('themes.team-manager.no-data-found')
			}
			return result
		},
		selectedThemes: function () {
			return this.service.teamManager.store.getSearchedThemes()
		},
		themesList: function () {
			return this.service.teamManager.store.getThemes()
		},
		usersFullAccessList: function () {
			let result = {}
			const selectedThemes = this.selectedThemes
			this.users.forEach(user => {
				let userThemesEntries = Object.entries(user.themes)
				if (selectedThemes.length > 0) {
					userThemesEntries = userThemesEntries.filter(item => selectedThemes.includes(item[0]))
				}
				userThemesEntries = userThemesEntries.map(item => item[1])
				let status = 'some'
				if (userThemesEntries.every(access => access === 'all' || access === 'disabled')) {
					status = 'all'
				} else if (userThemesEntries.every(access => access === 'none' || access === 'disabled')) {
					status = 'none'
				}
				result[user.id] = status
			})
			return result
		}
	},
	watch: {
		filters: {
			deep: true,
			handler: function () {
				this.refreshUsers()
			},
			immediate: true
		},
		pagination: {
			deep: true,
			handler: function (newVal, oldVal) {
				if ((!oldVal && newVal) || newVal.page !== oldVal.page || newVal.rowsPerPage !== oldVal.rowsPerPage) {
					this.getUsers()
				}
			},
			immediate: true
		},
		accountingFirmId: {
			handler: function (newValue, oldValue) {
				if (newValue && (!oldValue || newValue != oldValue)) {
					this.refreshUsers()
				}
			}
		}
	},
	methods: {
		getUsers: function (reset = false) {
			this.loadings++
			if (reset) {
				this.users.clear()
				this.pagination = {
					rowsPerPage: 15,
					page: 1
				}
				this.totalUsers = 0
			}

			return this.service.teamManager
				.getUsers(this.pagination)
				.then(data => {
					this.totalUsers = data.total

					this.users.clear()
					this.users = data.data
				})
				.catch(err => {
					if (err.message != 'Request cancelled') {
						Promise.reject(err)
					}
				})
				.finally(() => {
					this.loadings--
				})
		},
		getHeaderSlotName: function (index) {
			return `header-cell-${index + 1}`
		},
		refreshUsers: function () {
			return this.getUsers(true)
		},
		themeToggled: function (themeTitle, status) {
			return this.service.teamManager.toggleThemeAssignment(themeTitle, status).then(() => {
				const theme = this.themesList.find(themeInList => themeInList.title === themeTitle)
				if (theme) {
					theme.status = status
				}
				this.users.forEach(user => {
					if (user.themes[themeTitle] !== 'disabled') {
						user.themes[themeTitle] = status
					}
				})
			})
		},
		userThemeToggled: function (user, themeTitle, status) {
			return this.service.teamManager.toggleUserThemeAssignment(user, themeTitle, status)
		},
		userToggled: function (user, status) {
			return this.service.teamManager.toggleUserThemeAssignments(user, status)
		}
	}
}
</script>
